import './css/index.css';
import { Suspense, useContext, useEffect, useReducer } from "react";
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { AtendimentoContext } from '../../contexts/AtendimentoContext';
import caAPI from '../../services/caAPI';
import { CallRecord } from '../../records/CallRecord/';
import { useQuery } from '../../hooks/useQuery';
import { Loading } from '../../components/Loading';
import { VoipContext } from '../../contexts/VoipContext';

export const CallRecordComponent = (props) => {
  const { inCallStatus } = useContext(VoipContext);
  let element = CallRecord['loading'];

  if (inCallStatus) {
    element = CallRecord['telefoneEmChamada'];
  } else if (props.screen && props.screen !== 'loading') {
    element = CallRecord[props.screen];
  } else {
    element = CallRecord['phone'];
  }
  return element(props);
};

const initialState = {
  contatoDestino: '',
  showVoipModal: false,
  contatosOrigem: [],
  contatoOrigemChosed: '',
  screen: 'loading',
  atendimento: null
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_CONTATO_DESTINO':
      return { ...state, contatoDestino: action.payload };
    case 'SET_SHOW_VOIP_MODAL':
      return { ...state, showVoipModal: action.payload };
    case 'SET_CONTATOS_ORIGEM':
      return { ...state, contatosOrigem: action.payload };
    case 'SET_CONTATO_ORIGEM_CHOSEN':
      return { ...state, contatoOrigemChosed: action.payload };
    case 'SET_SCREEN':
      return { ...state, screen: action.payload };
    case 'SET_ATENDIMENTO_ATIVO':
      return { ...state, atendimento: action.payload };
    case 'SET_IN_CALL_STATUS':
      return { ...state, inCallStatus: action.payload };
    default:
      return state;
  }
}

function Call() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setAtendimentoAtivo } = useContext(AtendimentoContext);
  const { inCallStatus } = useContext(VoipContext); // Obtendo o estado do contexto Voip
  const query = useQuery();

  const { contatoDestino, showVoipModal, contatosOrigem, contatoOrigemChosed } = state;

  useEffect(() => {
    // Atualiza a tela com base no estado inCallStatus
    if (inCallStatus) {
      dispatch({ type: 'SET_SCREEN', payload: 'telefoneEmChamada' });
    } else {
      dispatch({ type: 'SET_SCREEN', payload: 'phone' });
    }
  }, [inCallStatus]); // Executa toda vez que inCallStatus muda

  function setContatoDestino(numberText, flag) {
    let phone = '';
    if (contatoDestino.length <= 12 && flag === 'ADD') {
      phone = contatoDestino + numberText;
    }

    if (contatoDestino.length > 0 && flag === 'DEL') {
      phone = contatoDestino.slice(0, -1);
    }

    dispatch({ type: 'SET_CONTATO_DESTINO', payload: phone });
  }

  function handleCreateNewCallService() {
    if (contatoOrigemChosed === '') {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Escolha o contato origem para realizar a ligação'
      });
      return;
    }

    if (contatoDestino === '') {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Escolha o contato no qual você deseja efetuar uma ligação'
      });
      return;
    }

    const params = {
      contato_origem: contatoOrigemChosed,
      contato_destino: "+" + contatoDestino,
      meio_comunicacao: 'voip',
      tipo_atendimento: '1',
      situacao: '1',
      nome: 'Desconhecido',
      tags: []
    };

    trackPromise(caAPI.atendimento.create(params))
      .then(response => {
        if (response.status === 201 && response.data) {
          setAtendimentoAtivo(response.data);
          dispatch({ type: 'SET_SHOW_VOIP_MODAL', payload: true });
        }
      }).catch(error => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: 'Ooops!...',
          text: 'Não foi possível efetuar a ligação, tente novamente mais tarde'
        });
      });
  }

  const setContatosOrigemChosed = (text) => {
    dispatch({ type: 'SET_CONTATO_ORIGEM_CHOSEN', payload: text });
  };

  const getContatosOrigens = () => {
    trackPromise(caAPI.meio_comunicacao.buscarContatoOrigem('voip'))
      .then(response => {
        if (response.status === 200 && response.data) {
          dispatch({ type: 'SET_CONTATOS_ORIGEM', payload: response.data });
        }
      }).catch(error => {
        console.log(error);
      });
  };

  const defineComponent = () => {
    const screen = query.get('screen');
    let idAtendimento = query.get('atendimento');

    trackPromise(caAPI.atendimento.find(idAtendimento))
      .then(response => {
        if (response.status === 200 && response.data) {
          setAtendimentoAtivo(response.data);
          dispatch({ type: 'SET_ATENDIMENTO_ATIVO', payload: response.data });
        }

        if (screen) {
          dispatch({ type: 'SET_SCREEN', payload: screen });
        } else {
          getContatosOrigens();
        }
      }).catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    defineComponent();
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <CallRecordComponent 
        state={state} 
        setContatoDestino={setContatoDestino} 
        handleCreateNewCallService={handleCreateNewCallService} 
        setContatosOrigemChosed={setContatosOrigemChosed} 
        screen={state.screen} 
      />
    </Suspense>
  );
}

export default Call;
